import React from 'react'

const About = () => (
  <div className="row">
    <div className="col-md-6 offset-md-3">
      <h3>About...</h3>
    </div>
  </div>
)

export default About