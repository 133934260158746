import torrent from './reducers/torrent'
import thunk from 'redux-thunk'

import {
  combineReducers,
  createStore,
  applyMiddleware
} from 'redux'

const reducers = combineReducers({
  torrent
})

export default createStore(reducers, applyMiddleware(thunk))