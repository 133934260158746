import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Trending from './pages/Trending'
import Search from './pages/Search'
import Context from './context'
import Status from './pages/Status'
import About from './pages/About'
import Navbar from './components/UI/Navbar'
import { useLocalStorage } from './hooks/useLocalStorage'
import { toastConfig } from 'react-simple-toasts'
import 'react-simple-toasts/dist/theme/dark.css'; // choose your theme

const App = () => {

  const [search, setSearch] = useState('')
  // const [theme, setTheme] = useState(localStorage.getItem('app_theme') || 'light')
  const [theme, setTheme] = useLocalStorage('app_theme', 'light')
  const [navbar, setNavbar] = useState(false)

  const setSiteTheme = theme => document.querySelector('body').setAttribute('class', theme)

  const toggleTheme = () => {
    let newTheme = theme === 'dark' ? 'light' : 'dark'
    localStorage.setItem('app_theme', newTheme)
    setTheme(newTheme)
  }

  const toggleNavbar = () => {
    let showNavbar = !navbar
    setNavbar(showNavbar)
  }

  useEffect(() => {
    setSiteTheme(theme)
    toastConfig({
      theme: 'dark',
      position: 'top-right'
    })
    // eslint-disable-next-line
  }, [theme])

  const ContextProvider = {
    search,
    theme,
    setSearch,
    toggleTheme
  }

  return (
    <>
      <BrowserRouter>
        <Context.Provider value={ContextProvider}>
          <Navbar toggleNavbar={toggleNavbar} status={navbar} />
          <div className="container mt-4">
            <Switch>
              <Route path="/" exact component={Trending} />
              <Route path="/status" component={Status} />
              <Route path="/about" component={About} />
              <Route path="/search/:query" component={Search} />
            </Switch>
          </div>
        </Context.Provider>
      </BrowserRouter >
    </>
  )
}

export default App
