import React from 'react'
import ItemRow from './ItemRow'
import { sortTorrents } from '../../store/actions/torrent'
import { connect } from 'react-redux'
import { ReactComponent as SortIcon } from '../../static/icons/sort.svg'
import { ReactComponent as SortAscIcon } from '../../static/icons/sort-up.svg'
import { ReactComponent as SortDescIcon } from '../../static/icons/sort-down.svg'


const SortDetails = ({ sort, column, title }) => {
  let sortField = <SortIcon className="mt-1" width="10px" />
  if (sort.by === column && sort.order === 'desc') {
    sortField = <SortDescIcon className="mt-1" width="10px" />
  } else if (sort.by === column && sort.order === 'asc') {
    sortField = <SortAscIcon className="mt-1" width="10px" />
  }

  return (
    <div className="table-head-sort d-flex align-items-center">
      <span>{title}</span>
      <div className="sort-icon ml-1">
        {sortField}
      </div>
    </div>
  )
}

const ItemTable = ({ items, onSort, sort }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered torrent-list table-bordered table-sm table-striped small">
        <thead>
          <tr>
            <th width="auto">Title</th>
            <th onClick={() => onSort('size')} width="17%">
              <SortDetails title="Size" column="size" sort={sort} />
            </th>
            <th onClick={() => onSort('seeds')} width="6%">
              <SortDetails title="SE" column="leech" sort={sort} />
            </th>
            <th onClick={() => onSort('leechers')} width="6%">
              <SortDetails title="LE" column="leech" sort={sort} />
            </th>
            <th width="5%">Link</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => <ItemRow key={item.link} {...item} />)}
        </tbody>
      </table>
    </div>
  )
}

const mapDispatchToProps = {
  onSort: sort => sortTorrents(sort)
}

export default connect(null, mapDispatchToProps)(ItemTable)

