import React, { useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import Context from '../../context'

const Search = () => {

  const { search: query } = useContext(Context)

  const history = useHistory()
  const searchFormRef = useRef(null)

  useEffect(() => {
    searchFormRef.current.search.value = query
  }, [query])

  const onSearch = e => {
    e.preventDefault()
    const query = searchFormRef.current.search.value
    history.push(`/search/${query}`)
  }

  return (
    <form className="search-form mt-2 mt-sm-0 pb-2 pb-sm-0" ref={searchFormRef} onSubmit={onSearch} method="post">
      <div className="input-group input-group-lg">
        <input autoComplete="off" type="text" required name="search" className="form-control" placeholder="Start your search..." />
        <div className="input-group-append"><button title="Search" className="btn btn-primary">Search</button></div>
      </div>
    </form>
  )
}

export default Search