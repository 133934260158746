const Size = ({ children }) => {
  if (!children) {
    return 'N/A'
  }

  let base = children / 1000
  let value = null
  let postfix = 'GB'
  if (base <= 1000) {
    value = base
    postfix = 'KB'
  } else if (base < 1000000) {
    value = (base / 1000)
    postfix = 'MB'
  } else {
    value = (base / (1000 * 1000))
  }

  if (!value) {
    return value
  }

  return `${value.toFixed(2)} ${postfix}`
}

export default Size