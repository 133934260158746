import React, { useEffect } from 'react'
import ItemTable from '../components/ItemTable'
import Loader from '../components/UI/Loader'
import { connect } from 'react-redux'
import { getTrending, changePage } from '../store/actions/torrent'
import Pagination from '../components/UI/Pagination'
import WithSearch from '../components/HOC/WithSearch'


const Trending = ({ changePage, getTrending, torrents, currentPage, sort, loading }) => {

  useEffect(() => {
    getTrending()
    document.title = 'Torrent Search Engine | Trending Torrents'
    // eslint-disable-next-line
  }, [])

  const perPage = 50
  const offset = (currentPage - 1) * perPage
  const items = torrents.slice(offset, perPage * currentPage)

  return (
    <div className="row mt-3">
      <div className="col-12">
        {loading ? <Loader /> : (
          <>
            <ItemTable sort={sort} items={items} />
            <Pagination
              perPage={perPage}
              current={currentPage}
              onPageChange={changePage}
              total={torrents.length}
            />
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  torrents: state.torrent.data,
  loading: state.torrent.loading,
  currentPage: state.torrent.currentPage,
  sort: state.torrent.sort
})

const mapDispatchToProps = {
  getTrending,
  changePage
}

export default connect(mapStateToProps, mapDispatchToProps)(WithSearch(Trending))