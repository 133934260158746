import { useState } from 'react'

export const useLocalStorage = (key, defaultValue = '') => {
  const [value, setStateValue] = useState(localStorage.getItem(key) || defaultValue)

  const setValue = value => {
    setStateValue(value)
    localStorage.setItem(key, value)
  }

  return [
    value,
    setValue
  ]
}