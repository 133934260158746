import React from 'react'

const Pagination = ({ total, onPageChange, perPage, current }) => {
  let totalPages = Math.ceil(total / (perPage || 20))
  if (totalPages === 1) {
    return null
  }
  const numbers = []
  const links = []
  const offset = (current - 1) * perPage

  for (let i = 1; i <= totalPages; i++) {
    numbers.push(i)
  }

  for (let i = numbers.length - 3; i > 1; i--) {
    if (Math.abs(current - i - 1) > 2) {
      numbers.splice(i, 1)
    }
  }

  for (let i = 0; i < numbers.length; i++) {
    links.push(<li key={i} className={`page-item ${numbers[i] === current && 'active'}`}><button className="page-link" onClick={() => onPageChange(numbers[i])}>{numbers[i]}</button></li>)

    if ((numbers[i] + 1) !== numbers[i + 1] && numbers[i + 1]) {
      links.push(<li key={i + '_skip'} className='page-item'><button className="page-link">..</button></li>)
    }
  }

  const currentMax = current < totalPages ? (offset + perPage) : total

  return (
    <div className="row align-items-center">
      <div className="col-12 text-center text-md-left col-sm-12 col-md-6">
        <p>Showing <strong>{offset + 1}-{currentMax}</strong> from <strong>{total}</strong> Results</p>
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <ul className="pagination pagination-sm justify-content-center justify-content-md-end mt-2">
          {links}
        </ul>
      </div>
    </div>
  )
}

export default Pagination