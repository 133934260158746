import React, { useEffect, useContext } from 'react'
import ItemTable from '../components/ItemTable'
import Context from '../context'
import Loader from '../components/UI/Loader'
import { connect } from 'react-redux'
import { getSearchResults, changePage, resetTorrents } from '../store/actions/torrent'
import Pagination from '../components/UI/Pagination'
import WithSearch from '../components/HOC/WithSearch'

const Search = ({ match, changePage, onSearch, torrents, currentPage, sort, loading, resetTorrents }) => {
  const { query } = match.params

  const { setSearch } = useContext(Context)

  useEffect(() => {
    document.title = `Torrent Search Engine | Search for ${query}`
    window.dataLayer.push({
      event: 'pageView'
    })
    onSearch(query)
    setSearch(query)
    return () => {
      setSearch('')
      resetTorrents()
    }
    // eslint-disable-next-line
  }, [query])

  const perPage = 50

  const items = torrents.slice((currentPage - 1) * perPage, perPage * currentPage)

  return (
    <div className="row mt-3">
      <div className="col-12">
        {loading ? <Loader /> : (
          <>
            <h4 className="font-weight-light mb-4">Showing results for <strong>{query}</strong></h4>
            <ItemTable sort={sort} items={items} />
            <Pagination perPage={perPage} current={currentPage} onPageChange={changePage} total={torrents.length} />
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  torrents: state.torrent.data,
  loading: state.torrent.loading,
  currentPage: state.torrent.currentPage,
  sort: state.torrent.sort
})

const mapDispatchToProps = {
  onSearch: query => getSearchResults(query),
  changePage,
  resetTorrents
}

export default connect(mapStateToProps, mapDispatchToProps)(WithSearch(Search))