import React, { useContext } from 'react'
import { ReactComponent as LightIcon } from '../../static/icons/sun.svg'
import { ReactComponent as DarkIcon } from '../../static/icons/moon.svg'
import Context from '../../context'

const ThemeToggler = ({ classes }) => {
  const { theme, toggleTheme } = useContext(Context)

  return (
    <div className={`theme-toggler mt-2 ml-2 ${classes}`}>
      <button className="btn bg-transparent p-0" onClick={toggleTheme}>
        {theme === 'dark' ? <LightIcon fill="#f1c40f" width="20px" /> : <DarkIcon fill="#95a5a6" width="20px" />}
      </button>
    </div>
  )
}

export default ThemeToggler