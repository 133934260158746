import Torrent from '../../services/torrent'

export const getTrending = () => {
  return dispatch => {
    dispatch({ type: 'SET_LOADING' })
    Torrent.trending().then(data => {
      dispatch({
        type: 'SET_TORRENTS',
        data: data.torrents
      })
    })
  }
}

export const changePage = page => {
  return dispatch => {
    dispatch({
      type: 'SET_PAGE',
      page
    })
  }
}

export const sortTorrents = field => {
  return dispatch => {
    dispatch({ type: 'SORT', field })
  }
}

export const getSearchResults = query => {
  return dispatch => {
    dispatch({ type: 'SET_LOADING' })
    Torrent.search(query).then(data => {
      dispatch({
        type: 'SET_TORRENTS',
        data: data.torrents
      })
    })
  }
}

export const resetTorrents = () => {
  return dispatch => {
    dispatch({ type: 'RESET_TORRENTS' })
  }
}