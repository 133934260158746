const intialState = {
  sort: {
    order: null,
    by: null
  },
  data: [],
  total: 0,
  currentPage: 1,
}

const TorrentReducer = (state = intialState, action) => {
  if (action.type === 'SET_TORRENTS') {
    state = { ...state, data: action.data, total: action.data.length, currentPage: 1, loading: false }
  } else if (action.type === 'SET_PAGE') {
    state = { ...state, currentPage: action.page }
  } else if (action.type === 'SORT') {
    let sort = {}
    sort.order = state.sort.order === 'desc' ? 'asc' : 'desc'
    sort.by = action.field
    let data = sort.order === 'desc' ? state.data.sort((a, b) => b[sort.by] - a[sort.by]) : state.data.sort((a, b) => a[sort.by] - b[sort.by])
    state = { ...state, data, currentPage: 1, sort }
  } else if (action.type === 'SET_LOADING') {
    state = { ...state, loading: true }
  } else if (action.type === 'RESET_TORRENTS') {
    state = { ...intialState }
  }
  return state
}

export default TorrentReducer