import React, { useState } from 'react'
import { ReactComponent as Loader } from '../../../static/icons/bars.svg'
import { ReactComponent as MagnetIcon } from '../../../static/icons/magnet.svg'
import Torrent from '../../../services/torrent'
import Size from '../../UI/Size'
import toast from 'react-simple-toasts'

const ItemRow = ({ link, title, size, seeds, leech, magnet_link, name }) => {

  const [loading, setLoading] = useState(false)

  const openLink = () => {
    if (magnet_link) {
      return window.open(magnet_link, '_self')
    }
    setLoading(true)
    Torrent.detail(name.toLowerCase(), link).then(response => {
      setLoading(false)
      /** Copy to clipboard */
      navigator.clipboard.writeText(response.details.magnet_link);
      toast('Magnet link copied to clipboard.');
      /** It will open automatically if torrent client is installed */
      window.open(response.details.magnet_link, '_self')
    })
  }

  return (
    <tr>
      <td><a href={link} target="_blank" rel="noopener noreferrer">{title}</a></td>
      <td className="align-middle"><Size>{size}</Size></td>
      <td className="align-middle text-success">{seeds}</td>
      <td className="align-middle text-danger">{leech}</td>
      <td className="text-center align-middle">
        {!loading ? <button title="Open Magnet Link" onClick={openLink} className="btn bg-transparent p-0"><MagnetIcon width="15px" /></button> : <button className="btn bg-transparent p-0"><Loader width="17px" /></button>}
      </td>
    </tr>
  )
}

export default ItemRow