const Torrent = {

  baseUrl: `${process.env.REACT_APP_TORRENT_SERVER}/api/torrents`,

  async trending () {
    const response = await fetch(`${this.baseUrl}/trending`)
    return await response.json()
  },

  async search (query) {
    const response = await fetch(`${this.baseUrl}/search?query=${query}`)
    return await response.json()
  },

  async detail (name, url) {
    const response = await fetch(`${this.baseUrl}/${name}/details?url=${url}`)
    return await response.json()
  },

  async status () {
    const response = await fetch(`${this.baseUrl}/status`)
    return await response.json()
  },
}

export default Torrent