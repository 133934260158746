import React from 'react'
import { Link } from 'react-router-dom'
// import Search from '../Search'
import ThemeToggler from './ThemeToggler'

const Navbar = ({ toggleNavbar, status }) => {
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
      <div className="container">
        <Link className="navbar-brand" to="/">Torrent Engine</Link>
        <div className="right-icons d-flex">
          <button onClick={toggleNavbar} className="navbar-toggler p-0" type="button">
            <span className="navbar-toggler-icon"></span>
          </button>
          <ThemeToggler classes="d-block d-sm-none" />
        </div>

        <div className={`collapse navbar-collapse${status ? ' show' : ''}`}>
          <div className="ml-auto flex-column flex-column flex-md-row d-flex">
            <ul className="navbar-nav">
              <li className="nav-item"><Link className="nav-link" to="/status">Status</Link></li>
              <li className="nav-item mr-sm-2"><Link className="nav-link" to="/about">About</Link></li>
            </ul>
            <ThemeToggler classes="d-none d-sm-block" />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar