import React from 'react'
import { ReactComponent as LoaderIcon } from '../../static/icons/puff.svg'

const Loader = () => (
  <div className="text-center">
    <LoaderIcon width="100px" />
    <h4 className="font-weight-light">Please wait, while results are loading...</h4>
  </div>
)

export default Loader