import React from 'react'
import Search from '../../components/Search'


const WithSearch = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <>
          <div className="search-bar text-center my-5">
            <h1>Torrent Search Engine</h1>
            <div className="row mt-3">
              <div className="col-md-8 offset-md-2">
                <Search />
              </div>
            </div>
          </div>

          <WrappedComponent {...this.props} />
        </>
      )
    }
  }
}

export default WithSearch
