import React, { useEffect, useState } from 'react'
import Torrent from '../services/torrent'

const StatusBadge = ({ status }) => {
  let className = 'danger'
  let text = 'Offline'
  if (status) {
    className = 'success'
    text = 'Online'
  }
  return <span className={`badge badge-pill badge-${className}`}>{text}</span>
}


const Status = () => {
  const [sites, setSites] = useState([])
  useEffect(() => {
    Torrent.status().then(response => {
      setSites(response.sites)
    })
  }, [])

  return (
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <table className="table table-bordered small">
          <tbody>
            {sites.map(item => (
              <tr key={item.name}>
                <th>{item.name}</th>
                <td>
                  <StatusBadge status={item.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Status